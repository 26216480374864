import { makeStyles } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { Loading } from '../../views/Loading';

const useClasses = makeStyles({
    content: {
        wordBreak: 'break-word',
        overflow: 'hidden',
        borderRadius: '5px',
    },
    image: {
        height: '100%',
        width: '100%',
        maxHeight: '40vh',
        '&:hover': {
            transform: 'scale(1.1)', // Magnify the image by 10% on hover
        },
        borderRadius: '5px',
        transition: 'transform 0.3s ease', // Smooth transition for the magnification effect
    }
});

interface ChatHistoryImageContentProps {
    message: IChatMessage;
}

export const ChatHistoryImageContent: React.FC<ChatHistoryImageContentProps> = ({ message }) => {
    const classes = useClasses();
    const chat = useChat();
    const [imageURL, setImageURL] = useState<string | null>(null);

    const fetchImage = () => {
        if (message.messageImage == null) {
            return;
        }
        const imageString = message.messageImage.imageDataString;
        if (imageString) {
            const url = `data:image/png;base64,${imageString}`;
            setImageURL(url);
        } else {
            void chat.loadSingleImageString(message.messageImage.id)
                .then((imageString) => {
                    const url = `data:image/png;base64,${imageString}`;
                    setImageURL(url);
                });
        }
    };

    useEffect(() => {
        if (imageURL == null) { 
            fetchImage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div>
            {imageURL != null ? (
                <div className={classes.content}>
                    <img
                        src={imageURL}
                        className={classes.image}
                        alt="Chat Image" />
                </div>
            ) : (
                <Loading text={'Loading image...'} />
            )}
        </div>
    );
};